<template>
  <v-container>
    <h2>{{ $t("message.title-customer-detail") }}</h2>
    <div class="container">
      <div class="row">
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{ $t("message.label-email") }}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div
                style="width: 100%; padding: 5px 0px 5px 0px; height: 100%; border: 1px solid #263238;"
              >
                <span class="value" style="padding: 7px">{{
                  customerDetail.email
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{ $t("message.label-name") }}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div
                style="width: 100%; padding: 5px 0px 5px 0px; height: 100%; border: 1px solid #263238;"
              >
                <span class="value" style="padding: 7px">{{
                  customerDetail.customer_name
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{ $t("message.label-furigana") }}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div
                style="width: 100%; padding: 5px 0px 5px 0px; height: 100%; border: 1px solid #263238;"
              >
                <span class="value" style="padding: 7px">{{
                  customerDetail.furigana
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{ $t("message.label-phone") }}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div
                style="width: 100%; padding: 5px 0px 5px 0px; height: 100%; border: 1px solid #263238;"
              >
                <span class="value" style="padding: 7px">{{
                  customerDetail.phone
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{ $t("message.label-address") }}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div
                style="width: 100%; padding: 5px 0px 5px 0px; height: 100%; border: 1px solid #263238;"
              >
                <span class="value" style="padding: 5px"
                  >〒{{ customerDetail.postal_code }}
                  {{ customerDetail.prefecture }} {{ customerDetail.city }}
                  {{ customerDetail.district }} {{ customerDetail.ward }}
                  {{ customerDetail.street_address }}
                  {{ customerDetail.building }}</span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{ $t("message.label-gender") }}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div
                style="width: 100%; padding: 5px 0px 5px 0px; height: 100%; border: 1px solid #263238;"
              >
                <span class="value" style="padding: 7px">{{
                  labelGender(customerDetail.gender_id)
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{ $t("message.label-birthday") }}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div
                style="width: 100%; padding: 5px 0px 5px 0px; height: 100%; border: 1px solid #263238;"
              >
                <span class="value" style="padding: 7px">{{
                  customerDetail.bod
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{ $t("message.label-status") }}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div
                style="width: 100%; padding: 5px 0px 5px 0px; height: 100%; border: 1px solid #263238;"
              >
                <span class="value" style="padding: 7px">
                  {{ selectedStatus(customerDetail.is_active) }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{
                $t("message.label-date-registration")
              }}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div
                style="width: 100%; padding: 5px 0px 5px 0px; height: 100%; border: 1px solid #263238;"
              >
                <span class="value" style="padding: 7px">
                  {{ customerDetail.created_at }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <div class="fixed-div">
        <v-row class="d-flex justify-space-between mb-6">
          <v-col>
            <v-btn
              rounded
              color="secondary"
              dark
              style="float:start"
              @click.prevent="goBack"
            >
              {{ $t("message.btn-back") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              rounded
              color="primary"
              dark
              style="float:right"
              @click.prevent="toUpdate"
            >
              {{ $t("message.btn-update") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {};
  },
  computed: {
    customerDetail() {
      return this.$store.state.customer_module.customerResult;
    },
  },
  created() {
    this.dispatchCustomerDetail();
  },
  methods: {
    dispatchCustomerDetail() {
      let id = this.$router.history.current.params.id;
      this.$store.dispatch("customer_module/fetchCustomerDetail", id);
    },
    labelGender(data) {
      if (data == 1) {
        return this.$t("message.label-male");
      } else {
        return this.$t("message.label-female");
      }
    },
    selectedStatus(status) {
      if (status == 1) {
        return this.$t("message.label-active");
      } else {
        return this.$t("message.label-inactive");
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    toUpdate() {
      let id = this.$router.history.current.params.id;
      this.$router.push(`${id}/update`);
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  .nested-label-col-2 {
    padding-top: 0;
    padding-bottom: 0;
    width: 200vw;
    margin-left: 17px;
  }
  .nested-label-col-3 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.label {
  text-transform: capitalize;
}
.label-title {
  text-transform: capitalize;
  color: #3f51b5;
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fixed-div {
  margin-top: auto;
  bottom: 0px;
  right: 50%;
}
</style>
